<template>
  <div class="affiliates-landing-page">
    <hsLoading v-if="isLoading" />
    <div
      v-else
      data-testid="landing-page-content"
      class="affiliates-landing-page__content tlw-grid md:tlw-p-11 md:tlw-gap-x-8 md:tlw-gap-16 md:tlw-grid-cols-2 tlw-gap-12 tlw-p-4"
    >
      <div class="affiliates-landing-page__hero">
        <img :src="images.logo" alt="Spark Affiliates Logo" />

        <div class="tlw-mt-9 tlw-font-bold tlw-text-3xl">
          {{ $t('landing-page.views.sparkaffiliates-landing-page.index.title') }}
        </div>

        <div class="tlw-pt-4">
          {{ $t('landing-page.views.sparkaffiliates-landing-page.index.subtitle') }}
        </div>
      </div>

      <div class="affiliates-landing-page__hero-logo md:tlw-block md:tlw-justify-self-center tlw-hidden">
        <img :src="images.holdCard" alt="Pagamento" />
      </div>

      <div class="affiliates-landing-page__admin-card">
        <ProducerCard @enableSparkAffiliatesFeature="enableSparkAffiliatesFeature" />
      </div>

      <div class="affiliates-landing-page__affiliate-card">
        <AffiliateCard @enableAffiliationFeature="enableAffiliationFeature" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import toastHelper from '@/shared/helpers/toast';
import organizationService from '@/sparkaffiliates/services/organization.js';

export default {
  name: 'SparkAffiliatesLandingPage',
  data() {
    return {
      isLoading: false,
      images: {
        logo: require('@/assets/images/SparkAffiliates/sparkaffiliates-logo.svg'),
        holdCard: require('@/assets/images/SparkAffiliates/LandingPage/hold-card.svg'),
      },
    };
  },
  components: {
    hsLoading: () => import('@/components/Loading'),
    ProducerCard: () => import('./components/ProducerCard'),
    AffiliateCard: () => import('./components/AffiliateCard'),
  },
  computed: {
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
  },
  methods: {
    ...mapActions('school', ['enableFeature']),
    async enableSparkAffiliatesFeature() {
      try {
        this.isLoading = true;

        await organizationService.createOrganization({
          core_organization_id: this.school.id,
          platform_name: this.school.account_type,
        });

        await this.enableFeature({
          featureId: parseInt(process.env.VUE_APP_SPARKAFFILIATES_FEATURE_ID),
          featureKey: 'spark_affiliates',
        });

        this.$router.push({ name: 'ProducerPanel' });
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('landing-page.views.sparkaffiliates-landing-page.index.toast-messages.feature-activation-error')
        );
      }
      this.isLoading = false;
    },
    async enableAffiliationFeature() {
      // TODO
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliates-landing-page__content {
  grid-template-areas:
    'hero'
    'admin-card'
    'affiliate-card';
}

.affiliates-landing-page__hero {
  grid-area: hero;
}

.affiliates-landing-page__admin-card {
  grid-area: admin-card;
}

.affiliates-landing-page__affiliate-card {
  grid-area: affiliate-card;
}

.affiliates-landing-page__hero-logo {
  grid-area: hero-logo;
}

@media (min-width: $screen-bs-md) {
  .affiliates-landing-page__content {
    grid-template-areas:
      'hero hero-logo'
      'admin-card affiliate-card';
  }
}
</style>
